import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Image from "../../Image";
import "./index.less";

const LoginHeaderMobile = () => {
  const navigate = useNavigate(); // 系统
  const commonConfig = useSelector((state) => state?.App?.commonConfig); // 系统配置

  return (
    <header className="header_mobile_m">
      <div
        className="logo"
        onClick={() => {
          navigate("/");
        }}
      >
        {/* <Image className='logo_img' src={require('./assets/logo@3x.png')} alt='' /> */}
        {commonConfig && commonConfig.h5_logo && (
          <Image className="logo_img" src={commonConfig.h5_logo} alt="" />
        )}
      </div>
      <div
        className="btn_close"
        onClick={() => {
          navigate(-1);
        }}
      >
        <Image src={require("./assets/t_icon_top_close@3x.png")} alt="" />
      </div>
    </header>
  );
};

export default LoginHeaderMobile;
