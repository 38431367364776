import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Image from "../../../../../components/Image";
import PersonalUserInfo from "../PersonalUserInfo";
import PersonalWeb from "../PersonalWeb";
import PersonalEditTel from "../PersonalEditTel";
import PersonalBindTel from "../PersonalBindTel";
import PersonalEditPwd from "../PersonalEditPwd";
import Footer from "../../../../../components/pc/Footer";
import BuoyBox from "../../../../../components/pc/BuoyBox";
import "./index.less";

const PersonalSection = () => {
  const navigate = useNavigate();

  // 用户
  const userInfo = useSelector((state) => state?.User?.userInfo); // 用户信息

  // 侧边栏
  const asideList = [
    { id: 1, title: "我的资料", link: "" },
    // { id: 2, title: '我的资源', link: '' },
    { id: 3, title: "手机号设置", link: "" },
    { id: 4, title: "修改密码", link: "" },
  ]; // 侧边栏列表
  const [asideIndex, setAsideIndex] = useState(0);

  // 置顶
  const boxRef = useRef(null);
  const [scrollTop, setScrollTop] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);
  const goToTop = (animation = true) => {
    // 置顶事件
    if (animation) {
      let myTimer = setInterval(() => {
        boxRef.current.scrollTop -= 60;
        if (boxRef.current.scrollTop <= 0) {
          boxRef.current.scrollTop = 0;
          window.clearInterval(myTimer);
        }
      }, 10);
    } else {
      boxRef.current.scrollTop = 0;
    }
  };
  useEffect(() => {
    // 页面滚动
    window.addEventListener(
      "scroll",
      (e) => {
        setScrollTop(e?.target?.scrollTop);
        setClientHeight(e?.target?.clientHeight);
      },
      true
    );
  }, []);

  const tabsBox = (key) => {
    switch (key) {
      case 0:
        return <PersonalUserInfo />;
      // case 1:
      //   return <PersonalWeb />;
      case 1:
        return userInfo?.mobile !== "" ? (
          <PersonalEditTel />
        ) : (
          <PersonalBindTel />
        );
      case 2:
        return <PersonalEditPwd />;
      default:
        break;
    }
  };

  return (
    <section ref={boxRef} className="personal_section">
      <div className="personal_box">
        <div className="personal_box_header">
          <span
            className="personal_box_header_item"
            onClick={() => {
              navigate("/");
            }}
          >
            首页
          </span>
          <span className="personal_box_header_arrow">&gt;</span>
          <span className="personal_box_header_item item_active">个人中心</span>
        </div>
        <div className="personal_box_main">
          <aside className="personal_box_aside">
            <div className="personal_box_aside_header">
              <div className="personal_box_aside_header_img">
                <Image
                  src={
                    userInfo?.avatar
                      ? userInfo?.avatar
                      : require("./assets/img_avatar_n@2x.png")
                  }
                  alt=""
                />
              </div>
              <div
                className="personal_box_aside_header_name"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {userInfo?.user_name}
                {userInfo?.is_vip == "1" && (
                  <span
                    style={{
                      marginLeft: 4,
                      padding: "4px 8px",
                      height: "max-content",
                      backgroundColor: "#FAAD14",
                      borderRadius: 2,
                      fontSize: 12,
                      fontWeight: "400",
                      color: "#fff",
                      lineHeight: "12px",
                    }}
                  >
                    VIP
                  </span>
                )}
              </div>
            </div>
            <div className="personal_box_aside_main">
              {asideList &&
                asideList?.length > 0 &&
                asideList?.map((m, index) => (
                  <div
                    className={`personal_box_aside_main_item ${
                      asideIndex === index && "item_active"
                    }`}
                    key={"aside_list_item_" + index}
                    onClick={() => {
                      setAsideIndex(index);
                    }}
                  >
                    {m?.title}
                  </div>
                ))}
            </div>
          </aside>
          <article className="personal_box_article">
            {tabsBox(asideIndex)}
          </article>
        </div>
      </div>
      <Footer />
      <BuoyBox isShowTop={scrollTop > clientHeight} onTop={goToTop} />
    </section>
  );
};

export default PersonalSection;
