import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, message } from "antd";
import { useNavigate } from "react-router-dom";
import Image from "../../Image";
import QRCode from "qrcode.react";
import HTTP from "../../../utils/Http";
import "./index.less";

let payment_modal_timer;
const PaymentModal = ({
  open = false,
  item = {},
  setItem = () => {},
  onCancel = () => {},
}) => {
  const navigate = useNavigate();
  const token = useSelector((state) => state?.App?.token);
  const [btnPayment, setBtnPayment] = useState(0); // 去付款内容/扫码支付内容/已支付
  const [loading, setLoading] = useState(false);
  const [pcPayment, setPcPayment] = useState(null); // 订单参数
  const [paymentItem, setPaymentItem] = useState(null);
  const getData = async () => {
    // 获取详情
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/soft/getPriceById" + `?t=${new Date().getTime()}`,
        {
          token,
          id: item?.id ? item?.id : "",
        }
      );
      if (res.status === 10000) {
        setPaymentItem(res?.data);
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const getPcPayment = async () => {
    await getPcPaymentApi();
  };
  const getPcPaymentApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/soft/buyProductWeb" + `?t=${new Date().getTime()}`,
        {
          token,
          plugin_id: paymentItem?.id,
          pay_type: 2, // 支付方式 1 支付宝 2 微信
        }
      );
      if (res.status === 10000) {
        setPcPayment(res?.data);
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const getOrderStatusApi = async () => {
    // 订单状态查询
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/soft/getOrderStatus" + `?t=${new Date().getTime()}`,
        {
          token,
          order_id: pcPayment?.order_id,
        }
      );
      if (res.status === 10000) {
        if (res?.data?.status === 1) {
          // onCancel();
          clearInterval(payment_modal_timer);
          setBtnPayment(2);
        }
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const VerificationPayment = (isOpen) => {
    clearInterval(payment_modal_timer);
    if (isOpen) {
      payment_modal_timer = setInterval(async () => {
        await getOrderStatusApi();
      }, 2000);
    } else {
      clearInterval(payment_modal_timer);
    }
  };
  useEffect(() => {
    if (item && item?.is_has) {
      setBtnPayment(2);
    }
  }, [item]);
  useEffect(() => {
    if (btnPayment === 1) {
      VerificationPayment(btnPayment === 1);
    }
  }, [btnPayment]);
  useEffect(() => {
    if (!open) {
      clearInterval(payment_modal_timer);
      setPcPayment();
      setBtnPayment(0);
      setItem();
    } else {
      getData();
    }
  }, [open]);

  return (
    <Modal
      className="payment_modal"
      open={open}
      centered={true}
      closable={false}
      footer={null}
      maskStyle={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      onCancel={onCancel}
      destroyOnClose={true}
      afterClose={onCancel}
    >
      <div className="payment_modal_close" onClick={onCancel}>
        <Image src={require("./assets/t_icon_top_close@2x.png")} alt="" />
      </div>
      <span className="payment_modal_title">
        {btnPayment !== 1
          ? btnPayment === 0
            ? "付费内容"
            : "付费内容(已付费)"
          : "扫码支付"}
      </span>
      {btnPayment !== 1 && (
        <>
          <div className="payment_modal_warning">
            <Image src={require("./assets/icon_tip@2x.png")} alt="" />
            <span>
              {btnPayment === 2
                ? "您已付费，点击查看~"
                : "您当前查看的是付费内容，如需查看，请完成支付，购买后不支持退款~"}
            </span>
          </div>
          <div className="payment_modal_price">
            <span>支付金额：</span>
            <span className="price">
              {paymentItem
                ? paymentItem?.price !== ""
                  ? paymentItem?.price
                  : 0
                : 0}
            </span>
            <span>元</span>
          </div>
          <div className="payment_modal_btn">
            <div className="btn_actions">
              <div
                className={`btn margin_left ${
                  btnPayment === 2 ? "btn_payment2" : "btn_payment"
                }`}
                onClick={async () => {
                  if (btnPayment === 2) return;
                  await getPcPayment();
                  setBtnPayment(1);
                }}
              >
                {btnPayment === 2 ? "已付费" : "去付费"}
              </div>
              <div className="btn" onClick={onCancel}>
                取消
              </div>
            </div>
            <div
              className={`btn btn_view ${btnPayment === 2 ? "active" : ""}`}
              onClick={() => {
                if (btnPayment !== 2)
                  return message.warn({
                    content: "付费内容，请先完成支付",
                    style: { marginTop: "10vh" },
                  });
                navigate(`/product/details/${item?.pageType}/${item?.id}`);
              }}
            >
              查看
            </div>
          </div>
        </>
      )}

      {btnPayment === 1 && (
        <div className="payment_modal_code">
          <div className={`item`}>
            <div className="code_img_box">
              <QRCode
                value={
                  pcPayment
                    ? pcPayment?.pay_url !== ""
                      ? pcPayment?.pay_url
                      : ""
                    : ""
                }
              />
            </div>
            <div className="code_title">
              <Image src={require("./assets/WeChat@2x.png")} alt="" />
              <span>微信快捷支付</span>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default PaymentModal;
