import { useSelector } from "react-redux";
import Image from "../../Image";
import "./index.less";

const LoginFooter = () => {
  // 系统
  const commonConfig = useSelector((state) => state?.App?.commonConfig); // 系统配置

  return (
    <footer className="login_footer">
      {/* <span className='login_footer_title'>
        {commonConfig?.web_garecordnum !==''?commonConfig?.web_garecordnum+'｜':''}
        {commonConfig?.web_recordnum !==''?commonConfig?.web_recordnum+'｜':''}
        {commonConfig?.copyright_imformation !==''?commonConfig?.copyright_imformation:''}
      </span> */}
      {commonConfig?.web_garecordnum !== "" && (
        <>
          <Image
            className="login_footer_img"
            src={require("./assets/icon_icp@2x.png")}
            alt=""
          />

          <span
            className="login_footer_title"
            onClick={() => {
              window.open(`https://www.beian.gov.cn/portal/index.do`, "_blank");
            }}
          >
            {commonConfig?.web_garecordnum !== ""
              ? commonConfig?.web_garecordnum
              : ""}
          </span>
          <span className="footer_divider">｜</span>
        </>
      )}
      {commonConfig?.web_recordnum !== "" && (
        <>
          <span
            className="login_footer_title"
            onClick={() => {
              window.open(
                `https://beian.miit.gov.cn/#/Integrated/index`,
                "_blank"
              );
            }}
          >
            {commonConfig?.web_recordnum !== ""
              ? commonConfig?.web_recordnum
              : ""}
          </span>
          <span className="footer_divider">｜</span>
        </>
      )}
      {commonConfig?.copyright_imformation !== "" && (
        <>
          <span className="login_footer_title">
            {commonConfig?.copyright_imformation !== ""
              ? commonConfig?.copyright_imformation
              : ""}
          </span>
        </>
      )}
    </footer>
  );
};

export default LoginFooter;
