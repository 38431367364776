import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Image from '../../Image'
import './index.less'

const LoginHeader = () => {
  const navigate = useNavigate()
  // 系统 
  const commonConfig = useSelector(state => state?.App?.commonConfig) // 系统配置

  return (
    <>
    <header className='login_header'>
      {/* 左侧 */}
      <div className='logo'>
        {
          commonConfig && commonConfig.web_logo &&
          <Image className='logo_img' src={commonConfig.web_logo} alt='' />
        }
      </div>
      {/* 右侧 */}
      <div
        className='right'
        onClick={() => {
          navigate('/')
        }}
      >
        <div className='to_home_img'>
          <Image src={require('./assets/icon_home@2x.png')} alt='' />
        </div>
        <div className='to_home_title'>
          <div className=''>回到首页</div>
        </div>
      </div>
    </header>
    <div className='login_header_ccupy' />
    </>
  )
}

export default LoginHeader