import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setMenuIndex } from "../../store/menu";
import { getDocumentTitle } from "../../utils/getDocumentTitle";
import Head from "../../components/Head";
import Header from "../../components/pc/Header";
import SearchSection from "./components/pc/SearchSection";
import HeaderMobile from "../../components/mobile/HeaderMobile";
import SearchSectionMobile from "./components/mobile/SearchSectionMobile";
import { flexibleWidth } from "../../utils/flexible";
import "./index.less";

const Search = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const commonConfig = useSelector((state) => state?.App?.commonConfig);
  const menuList = useSelector((state) => state?.Menu?.menuList);

  let [plat, setPlat] = useState(
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  );
  // 页面配置
  const [windowWidth, setWindowWidth] = useState(
    document.documentElement.clientWidth
  ); // 页面宽度
  useEffect(() => {
    // 页面宽度变化
    flexibleWidth();
    setPlat(
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    );
    setWindowWidth(document.documentElement.clientWidth);
    window.addEventListener(
      "resize",
      () => {
        flexibleWidth();
        setPlat(
          navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
          )
        );
        setWindowWidth(document.documentElement.clientWidth);
      },
      true
    );
  }, []);
  useEffect(() => {
    dispatch(setMenuIndex(-1));
  }, [location]);

  return (
    <>
      <Head>
        <title>
          {getDocumentTitle(
            location?.pathname,
            menuList,
            commonConfig?.web_title
          ) ?? ""}
        </title>
        <meta
          name="keywords"
          content={commonConfig?.web_keywords ?? "关键词"}
        />
        <meta
          name="description"
          content={commonConfig?.web_description ?? "描述内容"}
        />
        <meta
          property="og:title"
          content={
            getDocumentTitle(
              location?.pathname,
              menuList,
              commonConfig?.web_title
            ) ?? ""
          }
        ></meta>
        <meta
          property="og:description"
          content={commonConfig?.web_description ?? "描述内容"}
        />
        <meta
          property="og:image"
          content={
            commonConfig?.web_icon ?? "https://www.easyspark.cn/favicon.ico"
          }
        ></meta>
      </Head>
      <div className="search">
        {!plat && windowWidth >= 821 ? (
          <>
            <Header />
            <SearchSection />
          </>
        ) : (
          <>
            <HeaderMobile />
            <SearchSectionMobile />
          </>
        )}
      </div>
    </>
  );
};

export default Search;
