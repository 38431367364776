import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Tabs } from "antd-mobile";
import PersonalUserInfoMobile from "../PersonalUserInfoMobile";
import PersonalWebMobile from "../PersonalWebMobile";
import PersonalEditTelMobile from "../PersonalEditTelMobile";
import PersonalBindTelMobile from "../PersonalBindTelMobile";
import PersonalEditPwdMobile from "../PersonalEditPwdMobile";
import FooterMobile from "../../../../../components/mobile/FooterMobile";
import BuoyBoxMobile from "../../../../../components/mobile/BuoyBoxMobile";
import Image from "../../../../../components/Image";
import "./index.less";

const PersonalSectionMobile = () => {
  const navigate = useNavigate();

  // 用户
  const userInfo = useSelector((state) => state?.User?.userInfo); // 用户信息

  // tabs选项卡
  const [activeKey, setActiveKey] = useState("1");
  const tabsList = [
    { id: 1, title: "我的资料", link: "" },
    // { id: 2, title: '我的资源', link: '' },
    { id: 3, title: "手机号码", link: "" },
    { id: 4, title: "修改密码", link: "" },
  ]; // 侧边栏列表

  // 置顶
  const boxRef = useRef(null);
  const [scrollTop, setScrollTop] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);
  const goToTop = (animation = true) => {
    // 置顶事件
    if (animation) {
      let myTimer = setInterval(() => {
        boxRef.current.scrollTop -= 60;
        if (boxRef.current.scrollTop <= 0) {
          boxRef.current.scrollTop = 0;
          window.clearInterval(myTimer);
        }
      }, 10);
    } else {
      boxRef.current.scrollTop = 0;
    }
  };
  useEffect(() => {
    // 页面滚动
    window.addEventListener(
      "scroll",
      (e) => {
        setScrollTop(e?.target?.scrollTop);
        setClientHeight(e?.target?.clientHeight);
      },
      true
    );
  }, []);

  const tabsBox = (key) => {
    switch (key) {
      case "1":
        return <PersonalUserInfoMobile />;
      // case '2':
      //   return <PersonalWebMobile />
      case "3":
        return userInfo?.mobile !== "" ? (
          <PersonalEditTelMobile />
        ) : (
          <PersonalBindTelMobile />
        );
      case "4":
        return <PersonalEditPwdMobile />;
      default:
        break;
    }
  };

  return (
    <section ref={boxRef} className="personal_section_m">
      <div className="personal_box_m">
        <div className="personal_box_m_header">
          <span
            className="personal_box_m_header_item"
            onClick={() => {
              navigate("/");
            }}
          >
            首页
          </span>
          <span className="personal_box_m_header_arrow">&gt;</span>
          <span className="personal_box_m_header_item">个人中心</span>
          <span className="personal_box_m_header_arrow">&gt;</span>
          <span className="personal_box_m_header_item item_active">
            {tabsList &&
              tabsList?.length > 0 &&
              tabsList.filter((f) => f.id + "" === activeKey) &&
              tabsList.filter((f) => f.id + "" === activeKey)?.length > 0 && [
                tabsList
                  .filter((f) => f.id + "" === activeKey)
                  ?.map((m) => m.title),
              ]}
          </span>
        </div>
        <div className="personal_box_m_information">
          <div className="personal_box_m_information_box">
            <div className="avatar">
              <Image
                src={
                  userInfo && userInfo?.avatar && userInfo?.avatar !== ""
                    ? userInfo?.avatar
                    : require("./assets/img_avatar_n_m@3x.png")
                }
                alt=""
              />
            </div>
            <div
              className="title"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {userInfo?.user_name ?? ""}
              {userInfo?.is_vip == "1" && (
                <span
                  style={{
                    marginLeft: 4,
                    padding: "4px 8px",
                    height: "max-content",
                    backgroundColor: "#FAAD14",
                    borderRadius: 2,
                    fontSize: 12,
                    fontWeight: "400",
                    color: "#fff",
                    lineHeight: "12px",
                  }}
                >
                  VIP
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="personal_box_m_tabs">
          <Tabs
            activeKey={activeKey}
            onChange={(e) => {
              setActiveKey(e);
            }}
          >
            {tabsList &&
              tabsList?.length > 0 &&
              tabsList?.map((m) => (
                <Tabs.Tab title={m?.title} key={m?.id + ""}>
                  {tabsBox(activeKey)}
                </Tabs.Tab>
              ))}
          </Tabs>
        </div>
        <FooterMobile isAbout={false} />
        <BuoyBoxMobile isShowTop={scrollTop > clientHeight} onTop={goToTop} />
      </div>
    </section>
  );
};

export default PersonalSectionMobile;
