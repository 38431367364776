import { useSelector } from "react-redux";
import Image from "../../Image";
import "./index.less";

const LoginFooterMobile = () => {
  // 系统
  const commonConfig = useSelector((state) => state?.App?.commonConfig); // 系统配置

  return (
    <footer className="login_footer_m">
      <div className="m_footer">
        {commonConfig?.web_garecordnum !== "" && (
          <div className="m_content1">
            <Image src={require("./assets/icon_icp@3x.png")} alt="" />

            <span
              onClick={() => {
                window.open(
                  `https://www.beian.gov.cn/portal/index.do`,
                  "_blank"
                );
              }}
            >
              {commonConfig?.web_garecordnum}
            </span>
          </div>
        )}
        {commonConfig?.web_recordnum !== "" && (
          <span
            className="m_content2"
            onClick={() => {
              window.open(
                `https://beian.miit.gov.cn/#/Integrated/index`,
                "_blank"
              );
            }}
          >
            {commonConfig?.web_recordnum}
          </span>
        )}
        {/* {commonConfig?.copyright_imformation !==''&&<span className='m_content2'>{commonConfig?.copyright_imformation}</span>} */}
      </div>
    </footer>
  );
};

export default LoginFooterMobile;
