import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { message } from 'antd'
import { setUserInfo } from '../../../../../store/user'
import HTTP from '../../../../../utils/Http'
import { dataVerification } from '../../../../../utils/formatChecks'
import FooterMobile from '../../../../../components/mobile/FooterMobile'
import './index.less'

let timers;
const PersonalEditTelMobile = () => {
  const dispatch = useDispatch()
  const token = useSelector(state => state?.App?.token)
  const userInfo = useSelector(state => state?.User?.userInfo)

  const [temp_token, setTempToken] = useState('')

  const tel = userInfo?.mobile ?? ''
  const [code, setCode] = useState('')
  const [tel2, setTel2] = useState('')
  const [code2, setCode2] = useState('')

  const [countDown, setCountDown] = useState(60) // 获取验证码倒计时
  const [isGetCode, setIsGetCode] = useState(true) // 是否可获取验证码
  // 验证码获取倒计时
  const countDownHandle = () => {
    let t = countDown
    setIsGetCode(false)
    timers = setInterval(() => {
      t--
      setCountDown(t)
      if(t === 0) {
        clearInterval(timers)
        setIsGetCode(true)
        setCountDown(60)
      }
    }, 1000)
  }
  const [loading, setLoading] = useState(false)
  const getCode = async (type) => {
    if (tel === '') return message.error({ content: '手机号不能为空', style: { marginTop: '10vh' } })
    if (dataVerification("tel", tel)) return message.error({ content: '手机号格式错误', style: { marginTop: '10vh' } })
    await getVerifyMobileApi(type)
  }
  const getVerifyMobileApi = async (type) => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/auth/verifyMobile'+`?t=${new Date().getTime()}`, { mobile: tel })
      if(type === 1) {
        if (res.status === 10000) {
          // 已注册
          await getCodeApi()
        } else {
          // 未注册
          message.warning({ content: res?.message, style: { marginTop: '10vh' } })
        }
      } else {
        if (res.status === 10000) {
          // 已注册
          message.warning({ content: '当前手机号已注册', style: { marginTop: '10vh' } })
        } else {
          // 未注册
          await getCodeApi()
        }
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  const getCodeApi = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/sms/sendCommonSMS'+`?t=${new Date().getTime()}`, { mobile: temp_token === '' ? tel : tel2 })
      if (res.status === 10000) {
        countDownHandle()
        message.success({ content: '验证码已发送', style: { marginTop: '10vh' } })
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false);
    }
  }

  const getCodeCheck = async () => {
    if (tel === '') return message.error({ content: '手机号不能为空', style: { marginTop: '10vh' } })
    if (dataVerification("tel", tel)) return message.error({ content: '手机号格式错误', style: { marginTop: '10vh' } })
    if (code === '') return message.error({ content: '验证码不能为空', style: { marginTop: '10vh' } })
    if (code.length < 6) return message.error({ content: '验证码格式错误', style: { marginTop: '10vh' } })
    await getCodeCheckApi()
  }
  const getCodeCheckApi = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/user/smsCodeCheck'+`?t=${new Date().getTime()}`, { token, code })
      if (res.status === 10000) {
        clearInterval(timers)
        setIsGetCode(true)
        setCountDown(60)
        setTempToken(res?.data?.temp_token)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false);
    }
  }

  const getEditTel = async () => {
    if (tel2 === '') return message.error({ content: '手机号不能为空', style: { marginTop: '10vh' } })
    if (dataVerification("tel", tel)) return message.error({ content: '手机号格式错误', style: { marginTop: '10vh' } })
    if (code2 === '') return message.error({ content: '验证码不能为空', style: { marginTop: '10vh' } })
    if (code2.length < 6) return message.error({ content: '验证码格式错误', style: { marginTop: '10vh' } })
    await getEditTelApi()
  }
  const getEditTelApi = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/user/mobileChange'+`?t=${new Date().getTime()}`, { token, mobile: tel2, code: code2, temp_token })
      if (res.status === 10000) {
        await getUserInfoApi()
        setTempToken('')
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  const getUserInfoApi = async (token) => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/user/userInfo'+`?t=${new Date().getTime()}`, { token })
      if (res.status === 10000) {
        setCode('')
        setTel2('')
        setCode2('')
        message.success({ content: res?.message, style: { marginTop: '10vh' } })
        await dispatch(setUserInfo(res?.data))
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div className='personal_userinfo_m'>
        <div style={{ height: '.5333rem' }}/>
        {/* <span className='personal_userinfo_m_title'>
          { temp_token === '' ? '修改手机号-验证' : '设置新手机号'}
        </span> */}
        {
          <>
          <div style={{ display: temp_token !== ''&&'none' }}>
            <div className='personal_userinfo_m_row'>
              <span className='personal_userinfo_m_row_title'>
                原手机号
              </span>
              <div className='yz_input_m'>
                <input
                  type={'tel'}
                  placeholder='请填写原手机号'
                  defaultValue={tel}
                  maxLength={11}
                  disabled
                />
              </div>
            </div>
            <div className='personal_userinfo_m_row'>
              <span className='personal_userinfo_m_row_title'>
                验证码
              </span>
              <div className='yz_input_m'>
                <input
                  type={'tel'}
                  placeholder='请填写验证码'
                  value={code}
                  maxLength={6}
                  onInput={(e) => { setCode(e?.target?.value) }}
                />
                <span
                  className={`btn_code_m ${!isGetCode && 'no_code'}`}
                  onClick={() => {
                    if (isGetCode) getCode(1)
                  }}
                >
                  {isGetCode ? '获取验证码' : `重新获取${countDown}`}
                </span>
              </div>
            </div>
          </div>
          <div style={{ display: temp_token === ''&&'none' }}>
            <div className='personal_userinfo_m_row'>
              <span className='personal_userinfo_m_row_title'>
                新手机号
              </span>
              <div className='yz_input_m'>
                <input
                  type={'tel'}
                  placeholder='请填写新手机号'
                  value={tel2}
                  maxLength={11}
                  onInput={(e) => { setTel2(e?.target?.value) }}
                />
              </div>
            </div>
            <div className='personal_userinfo_m_row'>
              <span className='personal_userinfo_m_row_title'>
                验证码
              </span>
              <div className='yz_input_m'>
                <input
                  type={'tel'}
                  placeholder='请填写验证码'
                  value={code2}
                  maxLength={6}
                  onInput={(e) => { setCode2(e?.target?.value) }}
                />
                <span
                  className={`btn_code_m ${!isGetCode && 'no_code'}`}
                  onClick={() => {
                    if (isGetCode) getCode(2)
                  }}
                >
                  {isGetCode ? '获取验证码' : `重新获取${countDown}`}
                </span>
              </div>
            </div>
          </div>
          </>
        }
        <div className='btn_m'>
          <div
            className='btn_save_m'
            onClick={async () => {
              if(temp_token === '') { await getCodeCheck() }
              else { await getEditTel() }
            }}
          >
            <span>{ temp_token === '' ? '验证原手机号' : '确认修改' }</span>
          </div>
        </div>
      </div>
      {/* <FooterMobile isAbout={false} /> */}
    </>
  )
}

export default PersonalEditTelMobile