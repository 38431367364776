import { useState } from 'react'
import YzModal from '../YzModal'
import './index.less'

const YzBackground = ({
  title='标题',
  content='内容',
  backgroundImage='',
  isShowButton=true
}) => {
  // 立即咨询弹窗
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <div
      className='yz_background'
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className='yz_background_box animate__animated animate__slideInDown'>
        <span className='yz_background_title'>{title}</span>
        <span className='yz_background_content'>{content}</span>
        {isShowButton&&
        <div className='btn_consult' onClick={() => { setIsModalOpen(true) }}>
          <span>
            立即咨询
          </span>
        </div>}
      </div>
      {/* 立即咨询弹窗 */}
      <YzModal open={isModalOpen} onCancel={() => setIsModalOpen(false) } />
    </div>
  )
}

export default YzBackground