import { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setMenuList } from "./store/menu";
import { message } from "antd";
import { HelmetProvider } from "react-helmet-async";
import { setCommonConfig } from "./store/app";
import HTTP from "./utils/Http";
import GetRoutes from "./routes";

const App = () => {
  const dispatch = useDispatch();
  const menuList = useSelector((state) => state?.Menu?.menuList);
  const [loading, setLoading] = useState(false);
  const getCommonApi = async () => {
    // 获取系统配置
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/common1/config" + `?t=${new Date().getTime()}`
      );
      if (res.status === 10000) {
        if (res?.data && res?.data?.stats_code) {
          let html = res?.data?.stats_code;
          let cont = document.getElementById("cont");
          cont.innerHTML = html;
          let oldScript = cont.getElementsByTagName("script")[0];
          cont.removeChild(oldScript);
          let newScript = document.createElement("script");
          newScript.type = "text/javascript";
          newScript.innerHTML = oldScript.innerHTML;
          cont.appendChild(newScript);
        }
        await dispatch(setCommonConfig(res?.data));
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const getMenuListApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/navigation/listByPosition" + `?t=${new Date().getTime()}`,
        { position: 1 }
      );
      if (res.status === 10000) {
        let target = res?.data?.map((item) => {
          return {
            key: item?.id,
            title: item?.name ?? "标题",
            link: item?.link ?? "/",
            backgroud: item?.backgroud,
            bg_title: item?.title,
            bg_content: item?.content,
            pageRoute: item?.text1,
            pageType: item?.text2,
            category_id: item?.category_id,
          };
        });
        dispatch(setMenuList(target));
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getCommonApi();
    getMenuListApi();
  }, []);

  return (
    <HelmetProvider>
      <div className="App">
        <Router basename="/">
          {menuList && menuList.length > 0 && <GetRoutes />}
        </Router>
      </div>
    </HelmetProvider>
  );
};

export default App;
