import { useState, useRef, forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { message } from 'antd'
import YzModalWeChat from '../../../../../components/pc/YzModalWeChat'
import Image from '../../../../../components/Image'
import HTTP from '../../../../../utils/Http'
import { getUserInfo } from '../../../../../utils/UserAction'
import './index.less'

const UploadFile = forwardRef((props, ref) => {
  return(
     <div>
         {props.children}
         <input ref={ref} type="file" accept="image/jpeg,image/jpg,image/png" onChange={props?.onChange} style={{
             display: "none"
         }} />
     </div>
  )
})

const PersonalUserInfo = () => {
  const token = useSelector(state => state?.App?.token)
  const userInfo = useSelector(state => state?.User?.userInfo)

  const fileRef = useRef(null)
  const [name, setName] = useState(userInfo?.user_name ?? '')
  const [imgUrl, setImgUrl] = useState(userInfo?.avatar)

  // 绑定微信弹窗
  const [isModalOpen, setIsModalOpen] = useState(false)

  const chooseFile = () => {
    fileRef.current.click();
  }
  const upload = async (e) => {
    let file = e.target.files[0]

    try {
      const res = await HTTP.uploadFile(
        "/api/v1/common/fileUpload",
        file,
        undefined,
        token
      )
      if(res?.status === 10000) {
        setImgUrl(res?.data?.url)
      }
    } catch (error) {
      message.error({ content: error, style: { marginTop: '10vh' } })
    }
  }
  const [loading, setLoading] = useState(false)
  const getSaveUser = async () => {
    if (name === '') return message.error({ content: '用户名不能为空', style: { marginTop: '10vh' } })
    await getSaveUserApi()
  } 
  const getSaveUserApi = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/user/update'+`?t=${new Date().getTime()}`, {
        token,
        username: name,
        avatar: imgUrl
      })
      if (res.status === 10000) {
        message.success({ content: res?.message, style: { marginTop: '10vh' } })
        await getUserInfo(token)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false);
    }
  }
  
  return (
    <div className='personal_userinfo'>
      <span className='personal_userinfo_title'>
        基本信息
      </span>
      <div className='personal_userinfo_row'>
        <span className='personal_userinfo_row_title'>
          头像
        </span>
        <div onClick={chooseFile}>
          <UploadFile ref={fileRef} onChange={upload}>
            <div className='personal_userinfo_row_img'>
              <Image src={imgUrl ? imgUrl : require('./assets/img_avatar_n_b@2x.png')} alt='' />
              <div className='personal_userinfo_row_img_edit'>
                更换头像
              </div>
            </div>
          </UploadFile>
        </div>
      </div>
      <div className='personal_userinfo_row'>
        <span className='personal_userinfo_row_title'>
          用户名
        </span>
        <div className='yz_input'>
          <input
            type={'text'}
            placeholder='暂未设置'
            value={name}
            maxLength={16}
            onInput={(e) => { setName(e?.target?.value) }}
          />
        </div>
      </div>
      <span className='personal_userinfo_title' style={{ marginTop: '.2292rem' }}>
        第三方账号
      </span>
      <div className='personal_userinfo_row'>
        <span className='personal_userinfo_row_title'>
          微信账号
        </span>
        <div className='yz_input'>
          <span
            className={`wechat ${userInfo?.open_id&&'yes_wechat'}`}
            onClick={() => {
              if (userInfo?.open_id === '') setIsModalOpen(true)
            }}
          >
            {
              userInfo?.open_id === '' ? '去绑定' : userInfo?.user_name
            }
          </span>
          {
            userInfo?.open_id !== '' && userInfo?.mobile !== '' &&
            <span
              className='wechat_relieve'
              onClick={() => {
                setIsModalOpen(true)
              }}
            >
              解除
            </span>
          }
        </div>
      </div>
      <div className='btn'>
        <div
          className='btn_save'
          onClick={getSaveUser}
        >
          <span>保存</span>
        </div>
      </div>
      {/* 绑定微信弹窗 */}
      <YzModalWeChat open={isModalOpen} onCancel={() => setIsModalOpen(false) } />
    </div>
  )
}

export default PersonalUserInfo