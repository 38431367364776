import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table, message } from "antd";
import moment from "moment";
import YzEmpty from "../../../../../components/pc/YzEmpty";
import HTTP from "../../../../../utils/Http";
import "./index.less";

// 状态标签组件
const StatusTag = ({
  title = "运行正常",
  color = "rgba(48, 191, 120, 1)",
  backgroundColor = "rgba(48, 191, 120, 0.1)",
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 72,
        height: 25,
        backgroundColor: backgroundColor,
        borderRadius: 2,
        fontSize: 15,
        color: color,
        lineHeight: "18px",
      }}
    >
      {title}
    </div>
  );
};
// 进度条组件
const YzProgress = ({ backgroundColor = "#FF4D4F", value = 30 }) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <div
        style={{
          width: 60,
          height: 10,
          backgroundColor: "#EAEAEA",
          borderRadius: 30,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: `${value}%`,
            height: "100%",
            backgroundColor: backgroundColor,
          }}
        />
      </div>
      <span
        style={{
          marginLeft: 4,
          fontSize: 15,
          color: "#171717",
          lineHeight: "18px",
        }}
      >
        {value}%
      </span>
    </div>
  );
};

// 平台监测
const PlatformMonitor = () => {
  const token = useSelector((state) => state?.App?.token);

  // 表格
  const columns = [
    {
      title: "",
      dataIndex: "key",
      key: "key",
      render: (text) => {
        return <span style={{ color: "#999" }}>{text}</span>;
      },
    },
    {
      title: "平台名称",
      dataIndex: "name",
      key: "name",
      render: (text) => {
        return <span style={{ color: "#2A7D49" }}>{text}</span>;
      },
    },
    {
      title: "平台状态",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        switch (text) {
          case 0:
            return (
              <StatusTag
                title="运行正常"
                color="rgba(48, 191, 120, 1)"
                backgroundColor="rgba(48, 191, 120, .1)"
              />
            );
          case 1:
            return (
              <StatusTag
                title="响应超时"
                color="rgba(250, 173, 20, 1)"
                backgroundColor="rgba(250, 173, 20, .1)"
              />
            );
          case 2:
            return (
              <StatusTag
                title="运行异常"
                color="rgba(255, 77, 79, 1)"
                backgroundColor="rgba(255, 77, 79, .1)"
              />
            );
          default:
            return <StatusTag title="状态未知" />;
        }
      },
    },
    {
      title: "响应时长",
      dataIndex: "xy_time",
      key: "xy_time",
      render: (text) => {
        return <span style={{ color: "000" }}>{text}ms</span>;
      },
    },
    {
      title: "检测时间",
      dataIndex: "jc_time",
      key: "jc_time",
      render: (text) => {
        return <span style={{ color: "000" }}>{text}</span>;
      },
    },
    {
      title: "有效期至",
      dataIndex: "yczq_time",
      key: "yczq_time",
      render: (text) => {
        return <span style={{ color: "000" }}>{text}</span>;
      },
    },
  ]; // 表头列表
  const [dataSource, setDataSource] = useState([]); // 表格数据列表

  const [loading, setLoading] = useState(false);
  const getData = async () => {
    await getDataApi();
  };
  const getDataApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/user/myPlatform" + `?t=${new Date().getTime()}`,
        { token }
      );
      if (res?.status === 10000) {
        let target = res?.data?.data?.map((item) => {
          return {
            key: item?.id,
            name: item?.name,
            status: item?.status,
            xy_time: item?.response_time ?? 0,
            jc_time: item?.detect_time
              ? moment(item?.detect_time).format("YYYY-MM-DD HH:mm")
              : "",
            yczq_time: "-",
          };
        });
        setDataSource(target);
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {dataSource && dataSource?.length > 0 ? (
        <Table
          scroll={{ x: "max-content" }}
          pagination={{
            defaultCurrent: 1,
            total: dataSource && dataSource.length,
            pageSize: 8,
            // showQuickJumper: true,
            showSizeChanger: false,
            hideOnSinglePage: true,
            position: ["bottomCenter"],
          }}
          dataSource={dataSource}
          columns={columns}
        />
      ) : (
        <div>
          <YzEmpty />
        </div>
      )}
    </>
  );
};

// 服务器监测
const ServerMonitor = () => {
  const token = useSelector((state) => state?.App?.token);
  // 表格
  const columns = [
    {
      title: "",
      dataIndex: "key",
      key: "key",
      render: (text) => {
        return <span style={{ color: "#999" }}>{text}</span>;
      },
    },
    {
      title: "服务器名称",
      dataIndex: "name",
      key: "name",
      render: (text) => {
        return <span style={{ color: "#2A7D49" }}>{text}</span>;
      },
    },
    {
      title: "服务器IP",
      dataIndex: "ip",
      key: "ip",
      render: (text) => {
        return <span style={{ color: "#171717" }}>{text}</span>;
      },
    },
    {
      title: "服务器状态",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        switch (text) {
          case 0:
            return (
              <StatusTag
                title="运行正常"
                color="rgba(48, 191, 120, 1)"
                backgroundColor="rgba(48, 191, 120, .1)"
              />
            );
          case 1:
            return (
              <StatusTag
                title="响应超时"
                color="rgba(250, 173, 20, 1)"
                backgroundColor="rgba(250, 173, 20, .1)"
              />
            );
          case 2:
            return (
              <StatusTag
                title="运行异常"
                color="rgba(255, 77, 79, 1)"
                backgroundColor="rgba(255, 77, 79, .1)"
              />
            );
          default:
            return <StatusTag title="状态未知" />;
        }
      },
    },
    {
      title: "检测时间",
      dataIndex: "jc_time",
      key: "jc_time",
      render: (text) => {
        return <span style={{ color: "000" }}>{text}</span>;
      },
    },
    {
      title: "CPU使用率",
      dataIndex: "cpu",
      key: "cpu",
      render: (text) => {
        return <YzProgress value={text} backgroundColor="#FF4D4F" />;
      },
    },
    {
      title: "内存使用率",
      dataIndex: "memory",
      key: "memory",
      render: (text) => {
        return <YzProgress value={text} backgroundColor="#FAAD14" />;
      },
    },
    {
      title: "磁盘使用率",
      dataIndex: "disk",
      key: "disk",
      render: (text) => {
        return <YzProgress value={text} backgroundColor="#30BF78" />;
      },
    },
  ]; // 表头列表
  const [dataSource, setDataSource] = useState([]); // 表格数据列表

  const [loading, setLoading] = useState(false);
  const getData = async () => {
    await getDataApi();
  };
  const getDataApi = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const res = await HTTP.postForm(
        "/api/v1/user/myServer" + `?t=${new Date().getTime()}`,
        { token }
      );
      if (res?.status === 10000) {
        let target = res?.data?.data?.map((item) => {
          return {
            key: item?.id,
            name: item?.name,
            ip: 0,
            status: item?.status,
            jc_time: item?.detect_time
              ? moment(item?.detect_time).format("YYYY-MM-DD HH:mm")
              : "",
            cpu: item?.cpu_usage ?? 0,
            memory: item?.memory_usage ?? 0,
            disk: item?.hard_disk_usage ?? 0,
          };
        });
        setDataSource(target);
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {dataSource && dataSource?.length > 0 ? (
        <Table
          className="table_ce"
          scroll={{ x: "max-content" }}
          pagination={{
            defaultCurrent: 1,
            total: dataSource && dataSource.length,
            pageSize: 8,
            // showQuickJumper: true,
            showSizeChanger: false,
            hideOnSinglePage: true,
            position: ["bottomCenter"],
          }}
          dataSource={dataSource}
          columns={columns}
        />
      ) : (
        <div>
          <YzEmpty />
        </div>
      )}
    </>
  );
};

// 设备监测
const EquipmentMonitor = () => {
  const token = useSelector((state) => state?.App?.token);

  // 表格
  const columns = [
    {
      title: "",
      dataIndex: "key",
      key: "key",
      render: (text) => {
        return <span style={{ color: "#999" }}>{text}</span>;
      },
    },
    {
      title: "设备名称",
      dataIndex: "name",
      key: "name",
      render: (text) => {
        return <span style={{ color: "#2A7D49" }}>{text}</span>;
      },
    },
    {
      title: "类型",
      dataIndex: "type",
      key: "type",
      render: (text) => {
        return <span style={{ color: "#171717" }}>{text}</span>;
      },
    },
    {
      title: "检测内容",
      dataIndex: "content",
      key: "content",
      render: (text) => {
        return <span style={{ color: "#171717" }}>{text}</span>;
      },
    },
    {
      title: "设备状态",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        switch (text) {
          case 0:
            return (
              <StatusTag
                title="运行正常"
                color="rgba(48, 191, 120, 1)"
                backgroundColor="rgba(48, 191, 120, .1)"
              />
            );
          case 1:
            return (
              <StatusTag
                title="响应超时"
                color="rgba(250, 173, 20, 1)"
                backgroundColor="rgba(250, 173, 20, .1)"
              />
            );
          case 2:
            return (
              <StatusTag
                title="运行异常"
                color="rgba(255, 77, 79, 1)"
                backgroundColor="rgba(255, 77, 79, .1)"
              />
            );
          default:
            return <StatusTag title="状态未知" />;
        }
      },
    },
  ]; // 表头列表
  const [dataSource, setDataSource] = useState([]); // 表格数据列表

  const [loading, setLoading] = useState(false);
  const getData = async () => {
    await getDataApi();
  };
  const getDataApi = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const res = await HTTP.postForm(
        "/api/v1/user/myDetect" + `?t=${new Date().getTime()}`,
        { token }
      );
      if (res?.status === 10000) {
        let target = res?.data?.data?.map((item) => {
          return {
            key: item?.id,
            name: item?.name ?? "-",
            type: "-",
            content: item?.detect_name ?? "-",
            status: item?.status,
          };
        });
        setDataSource(target);
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {dataSource && dataSource?.length > 0 ? (
        <Table
          scroll={{ x: "max-content" }}
          pagination={{
            defaultCurrent: 1,
            total: dataSource && dataSource.length,
            pageSize: 8,
            // showQuickJumper: true,
            showSizeChanger: false,
            hideOnSinglePage: true,
            position: ["bottomCenter"],
          }}
          dataSource={dataSource}
          columns={columns}
        />
      ) : (
        <div>
          <YzEmpty />
        </div>
      )}
    </>
  );
};

const PersonalWeb = () => {
  // tabs
  const tabsList = [
    { id: 1, title: "平台监测" },
    { id: 2, title: "服务器监测" },
    { id: 3, title: "设备监测" },
  ]; // tabs列表
  const [tabsIndex, setTabsIndex] = useState(0); // 选择项

  return (
    <div className="personal_web">
      <span className="personal_web_title">我的资源</span>
      <div className="personal_web_tabs">
        <div className="personal_web_tabs_header">
          {tabsList &&
            tabsList?.length > 0 &&
            tabsList?.map((m, index) => (
              <div
                key={"personal_web_tabs_header_item_" + index}
                className="personal_web_tabs_header_item"
                // onClick={() => { setTabsIndex(index) }}
                onMouseEnter={() => {
                  setTabsIndex(index);
                }}
              >
                <span
                  className={`personal_web_tabs_header_item_title
                    ${tabsIndex === index && "item_active"}`}
                >
                  {m?.title}
                </span>
                {tabsIndex === index && (
                  <div className="personal_web_tabs_header_item_underline" />
                )}
              </div>
            ))}
        </div>
        <div className="personal_web_tabs_divider" />
        <div className="personal_web_tabs_body">
          <div className="personal_web_tabs_table">
            {tabsIndex === 0 && <PlatformMonitor />}
            {tabsIndex === 1 && <ServerMonitor />}
            {tabsIndex === 2 && <EquipmentMonitor />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalWeb;
