import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { message } from "antd";
import Image from "../../Image";
import HTTP from "../../../utils/Http";
import "./index.less";

const FooterMobile = ({ isAbout = true }) => {
  // 系统
  const commonConfig = useSelector((state) => state?.App?.commonConfig); // 系统配置

  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState([]); // 联系方式
  const [code, setCode] = useState([]); // 联系我们二维码
  const getContact = async () => {
    // 获取关于我们联系方式列表
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/brand/detailByKey" + `?t=${new Date().getTime()}`,
        { key: "footer_contact_us" }
      );
      if (res.status === 10000) {
        setContact(res?.data);
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const getCode = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/brand/detailByKey" + `?t=${new Date().getTime()}`,
        { key: "index_code_upload" }
      );
      if (res.status === 10000) {
        setCode(res?.data);
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getContact();
    getCode();
  }, []);

  return (
    <footer className="footer_m">
      <div className="m_main">
        {isAbout && (
          <div className="m_about">
            <span className="m_about_title">联系我们</span>
            {contact &&
              contact?.Gallery_list &&
              contact?.Gallery_list?.length > 0 &&
              contact?.Gallery_list.map((item, index) => (
                <div
                  key={"m_about_link_" + item?.id}
                  className="m_about_link"
                  style={{ marginTop: index === 0 && 0 }}
                >
                  <Image src={item?.pic} alt="" />
                  <span>{item?.content}</span>
                </div>
              ))}
          </div>
        )}
        <div className={`m_code ${!isAbout && "margin_top0"}`}>
          {code &&
            code?.Gallery_list &&
            code?.Gallery_list?.length > 0 &&
            code?.Gallery_list?.map((item) => (
              <div key={"m_code_item_" + item?.id} className="m_code_item">
                <Image src={item?.pic} alt="" />
                <span>{item?.title}</span>
              </div>
            ))}
        </div>
      </div>
      <div className="m_footer">
        {commonConfig?.web_garecordnum !== "" && (
          <div className="m_content1">
            <Image src={require("./assets/icon_icp@3x.png")} alt="" />
            <span
              onClick={() => {
                window.open(
                  `https://www.beian.gov.cn/portal/index.do`,
                  "_blank"
                );
              }}
            >
              {commonConfig?.web_garecordnum}
            </span>
          </div>
        )}
        {commonConfig?.web_recordnum !== "" && (
          <span
            className="m_content2"
            onClick={() => {
              window.open(
                `https://beian.miit.gov.cn/#/Integrated/index`,
                "_blank"
              );
            }}
          >
            {commonConfig?.web_recordnum}
          </span>
        )}
      </div>
    </footer>
  );
};

export default FooterMobile;
