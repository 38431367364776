import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'
import { setMenuList } from '../../../../../store/menu'
import { setUserInfo } from '../../../../../store/user'
import { dataVerification } from '../../../../../utils/formatChecks'
import HTTP from '../../../../../utils/Http'
import './index.less'

const LoginBox = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = useSelector(state => state?.App?.token)

  const [tel, setTel] = useState('')
  const [code, setCode] = useState('')
  const [countDown, setCountDown] = useState(60) // 获取验证码倒计时
  const [isGetCode, setIsGetCode] = useState(true) // 是否可获取验证码
  // 验证码获取倒计时
  const countDownHandle = () => {
    let t = countDown
    setIsGetCode(false)
    const timer = setInterval(() => {
      t--
      setCountDown(t)
      if(t === 0) {
        clearInterval(timer)
        setIsGetCode(true)
        setCountDown(60)
      }
    }, 1000)
  }
  const [loading, setLoading] = useState(false)
  const getCode = async () => {
    if (tel === '') return message.error({ content: '手机号不能为空', style: { marginTop: '10vh' } })
    if (dataVerification("tel", tel)) return message.error({ content: '手机号格式错误', style: { marginTop: '10vh' } })
    await getVerifyMobileApi()
  }
  const getVerifyMobileApi = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/auth/verifyMobile'+`?t=${new Date().getTime()}`, { mobile: tel })
      if (res.status === 10000) {
        // 已注册
        message.warning({ content: '当前手机号已注册', style: { marginTop: '10vh' } })
      } else {
        // 未注册
        await getCodeApi()
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  const getCodeApi = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/sms/sendCommonSMS'+`?t=${new Date().getTime()}`, { mobile: tel })
      if (res.status === 10000) {
        countDownHandle()
        message.success({ content: '验证码已发送', style: { marginTop: '10vh' } })
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  const goNext = async () => {
    if (tel === '') return message.error({ content: '手机号不能为空', style: { marginTop: '10vh' } })
    if (dataVerification("tel", tel)) return message.error({ content: '手机号格式错误', style: { marginTop: '10vh' } })
    if (code === '') return message.error({ content: '验证码不能为空', style: { marginTop: '10vh' } })
    if (code.length < 6) return message.error({ content: '验证码格式错误', style: { marginTop: '10vh' } })
    await getTelBindApi()
  }
  const getTelBindApi = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/user/mobileBind'+`?t=${new Date().getTime()}`, {
        token,
        mobile: tel,
        code
      })
      if (res.status === 10000) {
        // 绑定手机号成功
        await getUserInfoApi()
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  const getUserInfoApi = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/user/userInfo'+`?t=${new Date().getTime()}`, { token })
      if (res.status === 10000) {
        await dispatch(setUserInfo(res?.data))
        await getMenuListApi()
        navigate(-1, { replace: true })
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  const getMenuListApi = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/navigation/listByPosition"+`?t=${new Date().getTime()}`, { position: 1 });
      if (res.status === 10000) {
        let target = res?.data?.map(item => {
          return {
            key: item?.id,
            title: item?.name ?? '标题',
            link: item?.link ?? '/',
            backgroud: item?.backgroud,
            bg_title: item?.title,
            bg_content: item?.content,
            pageRoute: item?.text1,
            pageType: item?.text2,
            category_id: item?.category_id
          }
        })
        dispatch(setMenuList(target))
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='login_register_section_box'>
      <div className='login_register_section_header'>
        <span className='login_register_section_title'>
          绑定手机号
        </span>
      </div>
      <div className='login_register_section_body'>
        <div className='yz_input' style={{ marginTop: '.0833rem' }}>
          <input
            type={'tel'}
            placeholder='请填写手机号'
            value={tel}
            maxLength={11}
            onInput={(e) => { setTel(e?.target?.value) }}
          />
        </div>
        <div className='yz_input' style={{ marginTop: '.1146rem' }}>
          <input
            type={'tel'}
            placeholder='请填写验证码'
            value={code}
            maxLength={6}
            onInput={(e) => { setCode(e?.target?.value) }}
          />
          <span
            className={`btn_code ${!isGetCode && 'no_code'}`}
            onClick={() => {
              if (isGetCode) getCode()
            }}
          >
            {isGetCode ? '获取验证码' : `重新获取${countDown}`}
          </span>
        </div>
        <div className='btn'>
          <div
            className='btn_register'
            onClick={goNext}
          >
            进入官网
          </div>
        </div>
      </div>
    </div>
  )
}

const LoginSection = () => {

  return (
    <section
      className='login_register_section'
      style={{ backgroundImage: `url(${require('./assets/img_home_banner@2x.png')})` }}
    >
      <LoginBox />
    </section>
  )
}

export default LoginSection