import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import Image from "../../Image";
import "./index.less";

const PaymentModalYes = ({
  open = false,
  item = { pageType: 1, id: 0 },
  title = "付费内容",
  content = "您已付费，点击查看~",
  onCancel = () => {},
}) => {
  const navigate = useNavigate();
  return (
    <Modal
      className="payment_modal"
      open={open}
      centered={true}
      closable={false}
      footer={null}
      maskStyle={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      onCancel={onCancel}
      destroyOnClose={true}
      afterClose={onCancel}
    >
      <div className="payment_modal_close" onClick={onCancel}>
        <Image src={require("./assets/t_icon_top_close@2x.png")} alt="" />
      </div>
      <span className="payment_modal_title">{title ?? ""}</span>
      <>
        <div className="payment_modal_warning">
          <Image src={require("./assets/icon_tip@2x.png")} alt="" />
          <span>{content ?? ""}</span>
        </div>
        <div className="payment_modal_btn">
          <div
            className="btn btn_payment margin_left"
            onClick={() => {
              navigate(`/product/details/${item?.pageType}/${item?.id}`);
            }}
          >
            查看
          </div>
          <div className="btn" onClick={onCancel}>
            取消
          </div>
        </div>
      </>
    </Modal>
  );
};

export default PaymentModalYes;
