import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";
import { setMenuIndex, setMenuList } from "../../../store/menu";
import HTTP from "../../../utils/Http";
import Image from "../../Image";
import { logout } from "../../../utils/UserAction";
import "./index.less";

const HeaderMobile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // 系统
  const commonConfig = useSelector((state) => state?.App?.commonConfig); // 系统配置
  // 用户
  const userInfo = useSelector((state) => state?.User?.userInfo); // 用户信息
  // 菜单
  const menuIndex = useSelector((state) => state?.Menu?.menuIndex);
  const menuList = useSelector((state) => state?.Menu?.menuList); // 菜单列表
  const [loading, setLoading] = useState(false);
  const getMenuListApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/navigation/listByPosition" + `?t=${new Date().getTime()}`,
        { position: 1 }
      );
      if (res.status === 10000) {
        let target = res?.data?.map((item) => {
          return {
            key: item?.id,
            title: item?.name ?? "标题",
            link: item?.link ?? "/",
            backgroud: item?.backgroud,
            bg_title: item?.title,
            bg_content: item?.content,
            pageRoute: item?.text1,
            pageType: item?.text2,
            category_id: item?.category_id,
          };
        });
        dispatch(setMenuList(target));
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };

  // 搜索
  const [searchValue, setSearchValue] = useState(""); // 搜索框内容
  const [isSearch, setIsSearch] = useState(false); // 是否弹出搜索框
  useEffect(() => {
    // 点击空白处收起搜索框
    if (isSearch) {
      window.addEventListener(
        "click",
        () => {
          setIsSearch(false);
        },
        false
      );
    } else {
      window.removeEventListener("click", () => {}, false);
    }
  }, [isSearch]);
  useEffect(() => {
    if (menuList.map((m) => m.link).indexOf(location.pathname) !== -1) {
      const [target] = menuList
        .filter((f) => f.link === location.pathname)
        .map((m) => m.link);
      dispatch(setMenuIndex(menuList.map((m) => m.link).indexOf(target)));
    }
  }, [location.pathname, menuList]);

  // 菜单
  const [isMenu, setIsMenu] = useState(false); // 是否弹出菜单

  return (
    <>
      <header className="header_mobile">
        <div
          className="logo"
          onClick={() => {
            navigate("/");
          }}
        >
          {/* <Image className='logo_img' src={require('./assets/logo@3x.png')} alt='' /> */}
          {commonConfig && commonConfig.h5_logo && (
            <Image className="logo_img" src={commonConfig.h5_logo} alt="" />
          )}
        </div>
        <div className="right">
          <div className="login">
            {!userInfo ? (
              <>
                <span
                  className="btn_login"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  登录
                </span>
                {/* <div className="divider" />
                <span
                  className="btn_register"
                  onClick={() => {
                    navigate("/register");
                  }}
                >
                  注册
                </span> */}
              </>
            ) : (
              <div
                className="btn_username"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                onClick={() => {
                  navigate("/personal");
                }}
              >
                {userInfo?.user_name !== "" ? userInfo?.user_name : "用户名"}
                {userInfo?.is_vip == "1" && (
                  <span
                    style={{
                      marginLeft: 4,
                      padding: "4px 8px",
                      height: "max-content",
                      backgroundColor: "#FAAD14",
                      borderRadius: 2,
                      fontSize: 12,
                      fontWeight: "400",
                      color: "#fff",
                      lineHeight: "12px",
                    }}
                  >
                    VIP
                  </span>
                )}
              </div>
            )}
          </div>
          <div
            className="btn_search"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setIsSearch(true);
            }}
          >
            <Image src={require("./assets/icon_top_search@3x.png")} alt="" />
          </div>
          <div
            className="btn_menu"
            onClick={() => {
              setIsMenu(true);
            }}
          >
            <Image src={require("./assets/t_icon_top_menu@3x.png")} alt="" />
          </div>
        </div>
        {/* 搜索框 */}
        <div
          className={isSearch ? "search_box search_active" : "search_box"}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="search_main">
            <div className="search_input">
              <input
                type={"text"}
                placeholder="请输入关键词"
                value={searchValue}
                onInput={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
            </div>
            <div
              className="btn_search"
              onClick={async () => {
                await navigate("/search?key=" + searchValue);
                await setIsSearch(false);
                await setSearchValue("");
              }}
            >
              <Image
                className="btn_search_img"
                src={require("./assets/icon_top_search_r_t@3x.png")}
                alt=""
              />
            </div>
          </div>
        </div>
        {isSearch && <div className="search_mask"></div>}
        {/* 菜单 */}
        <div
          className={`menu_box ${isMenu && "menu_active"}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <header className="header_mobile_m">
            <div className="logo">
              {/* <Image
                className="logo_img"
                src={require("./assets/logo@3x.png")}
                alt=""
              /> */}
              {commonConfig && commonConfig.h5_logo && (
                <Image className="logo_img" src={commonConfig.h5_logo} alt="" />
              )}
            </div>
            <div
              className="btn_close"
              onClick={() => {
                setIsMenu(false);
              }}
            >
              <Image src={require("./assets/t_icon_top_close@3x.png")} alt="" />
            </div>
          </header>
          <div className="menu_list_m">
            {menuList &&
              menuList?.length > 0 &&
              menuList?.map((m, index) => (
                <div
                  key={"menu_item_m_" + index}
                  className={`menu_item_m ${
                    menuIndex === index ? "menu_item_m_active" : ""
                  }`}
                  onClick={() => {
                    setIsMenu(false);
                    if (location.pathname === m.link) return;
                    navigate(m?.link);
                  }}
                >
                  {m?.title}
                </div>
              ))}
          </div>
          {userInfo && (
            <div className="menu_list_m menu_border_top">
              <div
                className="menu_item_m"
                onClick={() => {
                  setIsMenu(false);
                  dispatch(
                    setMenuIndex(menuList.map((m) => m.link).indexOf("/"))
                  );
                  navigate("/personal");
                }}
              >
                个人中心
              </div>
              <div
                className="menu_item_m"
                onClick={async () => {
                  // 清空当前用户信息
                  // dispatch(setToken(null))
                  // dispatch(setUserInfo(null))
                  await logout();
                  getMenuListApi();
                  setIsMenu(false);
                  navigate(-1);
                }}
              >
                退出登录
              </div>
            </div>
          )}
        </div>
      </header>
      <div className="header_mobile_ccupy" />
    </>
  );
};

export default HeaderMobile;
