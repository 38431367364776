import { useState, useEffect } from 'react'
import { message } from 'antd'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import YzEmptyMobile from '../../../../../components/mobile/YzEmptyMobile'
import Image from '../../../../../components/Image'
import moment from 'moment'
import HTTP from '../../../../../utils/Http'
import './index.less'

const NDMainMobile = ({
  category_id
}) => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const params = useParams()
  const [data, setData] = useState() // 新闻详情
  const [loading, setLoading] = useState(false)
  const [sort, setSort] = useState()
  const [sortId, setSortId] = useState(0)
  const [recommendArticle, setRecommendArticle] = useState()
  const getAddCountApi = async (id) => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/article/addCount'+`?t=${new Date().getTime()}`, { id })
      if (res.status === 10000) { }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  const getSortApi = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/article/getCategoryList'+`?t=${new Date().getTime()}`, {
        category_id
      })
      if (res.status === 10000) {
        setSort(res?.data)
        if(res?.data?.length > 0) {
          if (sortId === 0) {
            if(state && state?.sortId) return setSortId(state?.sortId)
            setSortId(res?.data?.[0]?.id)
          }
        }
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false);
    }
  }
  const getRecommendArticleApi = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/article/recommendArticle'+`?t=${new Date().getTime()}`, {
        category_id
      })
      if (res.status === 10000) {
        setRecommendArticle(res?.data)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false);
    }
  }
  const getData = async () => {  // 获取新闻详情
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/article/newsDetail'+`?t=${new Date().getTime()}`, {
        id: params.id,
        categoryId: state.sortId
      })
      if (res.status === 10000) {
        setData(res?.data)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getSortApi()
    getRecommendArticleApi()
  }, [state?.sortId])
  useEffect(() => {
    getData()
  }, [params])

  return (
    <div className='ndm_main_m'>
      {/* 文章 */}
      <div className='ndm_main_article_m'>
        <div className='ndm_main_article_header_m'>
          <span className='ndm_main_article_header_title_m'>{data?.title}</span>
          <div className='ndm_main_article_header_info_m'>
            <span>来自：{data?.author}</span>
            <span className='margin_left'>浏览量：{data?.read_count}</span>
            <span className='margin_left'>{moment(data?.release_at).fromNow()}</span>
          </div>
          {/* <span className='ndm_main_article_header_content_m'>{data?.summary}</span> */}
        </div>
        <div className='ndm_main_article_body_m' dangerouslySetInnerHTML={{ __html: data?.content }}></div>
        <div className='ndm_main_article_footer_m'>
          <div className='ndm_main_article_footer_box_m'>THE END</div>
        </div>
      </div>
      <div className='ndm_main_divider' />
      {/* 推荐 */}
      <div className='new_main_recommend_m'>
        <div className='new_main_recommend_box_m'>
          <span className='new_main_recommend_title_m'>推荐文章</span>
          {
            recommendArticle && recommendArticle.length > 0 &&
            recommendArticle?.map((m,index) =>
              <div
                className='new_main_recommend_item_m'
                key={'new_main_recommend_item_m_'+index}
                onClick={async () => {
                  if (m.aid===params?.id) return;
                  await navigate(`/news/details/1/${m.aid}`, { state: { sortId: m?.article_info?.category_id }})
                  await getAddCountApi(m.aid)
                }}
              >
                <Image src={m?.article_info?.cover} alt='' />
                <div className='new_main_recommend_item_content_m'>
                  <span>{m?.article_info?.title}</span>
                  <div className='new_main_recommend_item_info_m'>
                    <span>{moment(m?.article_info?.release_at).format('YYYY-MM-DD HH:mm')}</span>
                  </div>
                </div>
              </div>
            )
          }
          {
            (!recommendArticle || (recommendArticle.length===0)) &&
            <div style={{ flex: 1 }}>
              <YzEmptyMobile />
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default NDMainMobile