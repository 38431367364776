import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import Image from "../../Image";
import "./index.less";

const PaymentModalMobileYes = ({
  open = false,
  item = { pageType: 1, id: 0 },
  title = "付费内容",
  content = "您已付费，点击查看~",
  onCancel = () => {},
}) => {
  return (
    <Modal
      open={open}
      centered={true}
      closable={false}
      footer={null}
      className="payment_modal_m"
      maskStyle={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      onCancel={onCancel}
      destroyOnClose={true}
      afterClose={onCancel}
    >
      <div className="payment_modal_m_header">
        <span className="payment_modal_m_title">{title ?? ""}</span>
        <div className="payment_modal_m_close" onClick={onCancel}>
          <Image src={require("./assets/t_icon_top_close@3x.png")} alt="" />
        </div>
      </div>
      <div className="payment_modal_warning_m">
        <Image src={require("./assets/icon_tip@2x.png")} alt="" />
        <span>{content ?? ""}</span>
      </div>

      <div className="payment_modal_m_btn">
        <a
          className="pp_btn_m"
          href={`/product/details/${item?.pageType}/${item?.id}`}
        >
          查看
        </a>
      </div>
    </Modal>
  );
};

export default PaymentModalMobileYes;
