import { useState, useEffect } from "react";
import { Modal, message } from "antd";
import Image from "../../Image";
import HTTP from "../../../utils/Http";
import "./index.less";

const YzModalMobile = ({ open = false, onCancel = () => {} }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const getData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/brand/detailByKey" + `?t=${new Date().getTime()}`,
        { key: "contact_us_mobile" }
      );
      if (res.status === 10000) {
        setData(res?.data);
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Modal
      open={open}
      centered={true}
      closable={false}
      footer={null}
      className="yz_modal_m"
      maskStyle={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      onCancel={onCancel}
      destroyOnClose={true}
      afterClose={onCancel}
    >
      <div className="modal_close" onClick={onCancel}>
        <Image
          className="modal_close_img"
          src={require("./assets/t_icon_top_close@3x.png")}
          alt=""
        />
      </div>
      <div className="modal_header">
        <span className="modal_title">{data?.name}</span>
        <span className="modal_vice_title">{data?.value}</span>
      </div>
      <div className="modal_body">
        {data &&
          data?.Gallery_list &&
          data?.Gallery_list?.length > 0 &&
          data?.Gallery_list?.map((m, index) => (
            <div
              className={`modal_item ${
                index !== 0 && "modal_item_margin_left"
              }`}
              key={"modal_item_" + index}
            >
              <a
                href={m?.content !== "" ? m?.content : ""}
                onClick={(e) => {
                  if (m?.content === "") e.preventDefault();
                }}
              >
                <div className="modal_item_img_box">
                  <Image className="modal_item_img" src={m?.pic} alt="" />
                </div>
              </a>
              <span className="modal_item_title">{m?.title}</span>
            </div>
          ))}
      </div>
    </Modal>
  );
};

export default YzModalMobile;
