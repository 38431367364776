import { Modal } from "antd";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { message } from "antd";
// import { setOrderPage } from '../../../../../store/payment'
import Image from "../../Image";
import HTTP from "../../../utils/Http";
import "./index.less";

const PaymentModalMobile = ({
  open = false,
  item = {},
  onCancel = () => {},
}) => {
  const token = useSelector((state) => state?.App?.token);

  const [loading, setLoading] = useState(false);
  const [pcPayment, setPcPayment] = useState(); // 订单参数
  const [paymentItem, setPaymentItem] = useState(null);
  const getData = async () => {
    // 获取详情
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/soft/getPriceById" + `?t=${new Date().getTime()}`,
        {
          token,
          id: item?.id ? item?.id : "",
        }
      );
      if (res.status === 10000) {
        setPaymentItem(res?.data);
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const getPcPayment = async () => {
    await getPcPaymentApi();
  };
  const getPcPaymentApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/soft/buyProductH5" + `?t=${new Date().getTime()}`,
        {
          token,
          plugin_id: paymentItem?.id,
          pay_type: 2, // 支付方式 1 支付宝 2 微信
        }
      );
      if (res.status === 10000) {
        await setPcPayment(res?.data);
        await message.success({
          content: "提交订单成功",
          style: { marginTop: "10vh" },
        });
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const getOrderStatusApi = async () => {
    // 订单状态查询
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/soft/getOrderStatus" + `?t=${new Date().getTime()}`,
        {
          token,
          order_id: pcPayment?.order_id,
        }
      );
      if (res.status === 10000) {
        if (res?.data?.status === 1) {
          onCancel();
        }
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (open) {
      getData();
    }
  }, [open]);
  useEffect(() => {
    getOrderStatusApi();
  }, [pcPayment]);
  document.onvisibilitychange = async () => {
    await getOrderStatusApi();
  };

  return (
    <Modal
      open={open}
      centered={true}
      closable={false}
      footer={null}
      className="payment_modal_m"
      maskStyle={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      onCancel={onCancel}
      destroyOnClose={true}
      afterClose={onCancel}
    >
      <div className="payment_modal_m_header">
        <span className="payment_modal_m_title">付费内容</span>
        <div className="payment_modal_m_close" onClick={onCancel}>
          <Image src={require("./assets/t_icon_top_close@3x.png")} alt="" />
        </div>
      </div>
      <div className="payment_modal_warning_m">
        <Image src={require("./assets/icon_tip@2x.png")} alt="" />
        <span>
          {`您当前查看的是付费内容，如需查看，请完成支付，购买后不支持退款~`}
        </span>
      </div>

      <div className="payment_modal_m_btn">
        {pcPayment ? (
          <a className="pp_btn_m" href={pcPayment?.pay_url} target="_blank">
            立即支付{" "}
            {paymentItem
              ? paymentItem?.price !== ""
                ? paymentItem?.price
                : 0
              : 0}
            元
          </a>
        ) : (
          <div
            className="pp_btn_m btn_submit_m"
            onClick={async () => {
              await getPcPayment();
            }}
          >
            提交订单{" "}
            {paymentItem
              ? paymentItem?.price !== ""
                ? paymentItem?.price
                : 0
              : 0}
            元
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PaymentModalMobile;
