import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import Image from "../../Image";
import "./index.less";

const YzModalProductMobile = ({ open = false, onCancel = () => {} }) => {
  const navigate = useNavigate();

  return (
    <Modal
      open={open}
      centered={true}
      closable={false}
      footer={null}
      className="ymp_modal_m"
      maskStyle={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      onCancel={onCancel}
      destroyOnClose={true}
      afterClose={onCancel}
    >
      <div className="ymp_modal_header">
        <span className="ymp_modal_title">您还没有登录</span>
        <div className="ymp_modal_close" onClick={onCancel}>
          <Image src={require("./assets/t_icon_top_close@3x.png")} alt="" />
        </div>
      </div>
      <div className="ymp_modal_body">
        <span>请先登录或注册账号登录后再进行此操作</span>
      </div>
      <div className="ymp_modal_footer">
        <div
          className="btn"
          onClick={() => {
            navigate("/register");
          }}
        >
          注册账号
        </div>
        <div className="divider" />
        <div
          className="btn btn_login"
          onClick={() => {
            navigate("/login");
          }}
        >
          立即登录
        </div>
      </div>
    </Modal>
  );
};

export default YzModalProductMobile;
